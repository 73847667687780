/**
 * This allows us to format a string from the database or another location into a float.
 *
 * @param value
 * @returns {number}
 */
export function convertStringToFloat(value) {
  // debug(`view:convertStringToFloat for ${value}`);
  if (typeof value !== 'number') {
    if (value == null) {
      return 0;
    }

    // Strip all characters other than digits, -, and . We need parseFloat her because in cases where you have more than one period the global search may not pick it up
    return parseFloat(value.toString().replace(/[^\d.-]/g, ''));
  }

  return parseFloat(value);
}

/**
 * Format a number as currency.
 *
 * @param value
 * @param decPlaces
 * @param decimalSep
 * @param thousandsSep = ','
 * @returns {string}
 */
export function formatMoney(value, decPlaces = 2, decimalSep = '.', thousandsSep = ',') {
  const sign = value < 0 ? '-' : '';
  const i = String(parseInt(value = Math.abs(Number(value) || 0).toFixed(decPlaces), 10));
  const j = (i.length) > 3 ? i.length % 3 : 0;

  return sign + (j ? i.substr(0, j) + thousandsSep : '')
    + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousandsSep}`)
    + (decPlaces ? decimalSep + Math.abs(value - i).toFixed(decPlaces).slice(2) : '');
}

/**
 * Format a value based on the requested type.
 *
 * @param value
 * @param type
 * @returns {*}
 */
export function formatValueAsType(value, type) {
  let finalValue;
  // debug('view:formatValueAsType for ' + value + ' (' + type + ')');
  // debug( value + ' ' + type);
  let valueToFormat = value;

  if (valueToFormat == null) {
    valueToFormat = 0;
    // return 0;
  }

  // Strip all characters other than digits, -, and . We need parseFloat her because in cases where you have more than one period the global search may not pick it up
  const cleanedValue = parseFloat(valueToFormat.toString().replace(/[^\d.-]/g, ''));
  let prefix = '';
  let suffix = '';

  switch (type) {
    case 'currency':
      prefix = '$';
      finalValue = formatMoney(cleanedValue, 2);
      break;
    case 'int':
      finalValue = formatMoney(cleanedValue, 0);
      break;
    case 'float':
      finalValue = cleanedValue.toFixed(2);
      break;
    case 'percent':
      finalValue = formatMoney((valueToFormat * 100), 4);
      suffix = '%';
      break;
    default:
      break;
  }

  return prefix + finalValue + suffix;
}

/**
 * Parse floats and handle NaN.
 *
 * @param val
 * @returns {number}
 */
export function parseFloatWithNaN(val) {
  const newVal = parseFloat(val);

  if (Number.isNaN(newVal)) {
    return 0;
  }

  return newVal;
}
