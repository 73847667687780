class JsonRpcClient {
  constructor(endpoint = '/perfectbuild/rpc', defaultHeaders = { Accept: 'application/json', 'Content-Type': 'application/json' }, headers = {}) {
    this.lastId = 0;
    this.endpoint = endpoint;
    this.headers = { ...defaultHeaders, ...headers };
  }

  request(method, params, signal = null) {
    this.lastId += 1;
    const id = this.lastId;

    const req = {
      method: 'POST',
      headers: this.headers,
      signal,
      body: JSON.stringify({
        jsonrpc: '2.0',
        id,
        method,
        params,
      }),
    };

    // console.log(req);

    return fetch(this.endpoint, req).then((res) => res.json());
  }

  requestWithFormData(method, params) {
    const req = {
      method: 'POST',
      body: params.formData,
    };

    return fetch(this.endpoint, req).then((res) => res.json());
  }
}

export default JsonRpcClient;
