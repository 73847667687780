import swal from 'sweetalert2';
import JsonRpcClient from '../../JsonRpcClient';
import { init as initAdSets } from './ad_sets';
import { init as initAutoOptimize } from './auto_optimize';
import { init as initCreatives } from './creatives';
import { init as initBudgetingFullService } from './budgeting_full_service';
import { init as initBuilds } from './builds';
import { init as initInfo } from './info';
import { init as initGoals } from './goals';
import { init as initChecks } from './checks';
import { init as initStatus } from './status';
import { init as initMultipliersAdditional } from './multipliers_additional';
import Utilities from '../../Utilities';
import CampaignEditUtilities from './CampaignEditUtilities';

const businessID = Utilities.getUrlParameter('business');
const campaignID = Utilities.getUrlParameter('campaign_id');
const projectID = Utilities.getUrlParameter('project');

/**
 *
 * FUNCTIONS
 *
 */

const initEmployeeItems = () => {
  if ($('.tab-pane#builds').length > 0) {
    initBuilds();
  }
  // if ($('.tab-pane#reporting').length > 0) {
  //   initReporting();
  // }
  if ($('.tab-pane#adsets').length > 0) {
    initAdSets();
  }
  if ($('.tab-pane#creatives').length > 0) {
    initCreatives();
  }
  if ($('.tab-pane#budgeting').length > 0) {
    initBudgetingFullService();
  }
  if ($('.tab-pane#auto-optimize').length > 0) {
    initAutoOptimize();
  }
};

export const init = () => {
  $(() => {
    /**
     *
     * INITIAL LOAD EVENTS
     *
     */
    if ($('.tab-pane#info').length > 0) {
      initInfo();
      initGoals();
      initChecks();
      initStatus();
      initMultipliersAdditional();
    }

    initEmployeeItems();

    /** INITIAL LOAD EVENTS -- END */

    /**
     *
     * EVENT TRIGGER HANDLERS
     *
     */
    let { hash } = window.location;
    if (hash === '') {
      hash = '#info';
    }
    if (hash) {
      $(`ul.nav a[href="${hash}"]`).tab('show');
    }

    $('.nav-tabs a').on('click', (e) => {
      $(e.currentTarget).tab('show');
      const scrollMem = $('body').scrollTop() || $('html').scrollTop();
      window.location.hash = e.currentTarget.hash;
      $('html,body').scrollTop(scrollMem);
    });

    $('[data-toggle="tooltip"]').tooltip();

    // If we have a catalog selected then we need to lock things down
    const onLoadCurrentCatalogForCampaign = $('#essentials-form select#fb_catalog_id option[selected]').val();
    CampaignEditUtilities.updateCatalogDropDowns();

    if (onLoadCurrentCatalogForCampaign != null && onLoadCurrentCatalogForCampaign !== '') {
      CampaignEditUtilities.lockCatalogDropDowns(onLoadCurrentCatalogForCampaign);
    }

    /** EVENT TRIGGER HANDLERS -- END */
  });
};

export default { init };
