import swal from 'sweetalert2';
import Utilities from '../../Utilities';
import CampaignEditUtilities from './CampaignEditUtilities';

const businessID = Utilities.getUrlParameter('business');
const campaignID = Utilities.getUrlParameter('campaign_id');
const projectID = Utilities.getUrlParameter('project');

/**
 *
 * FUNCTIONS
 *
 */

/**
 * Initialize the multiplier start date picker.
 *
 * @param date
 * @param maxDate
 */
function startDateMultiplier(date = false, maxDate = false) {
  $('#multiplier_start').daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    startDate: date.length ? date : false,
    maxDate: maxDate.length ? maxDate : false,
  },
  (start) => {
    const dateSelected = start.format('MM/DD/YYYY');
    $('#multiplier_start').val(dateSelected);
    // eslint-disable-next-line no-use-before-define
    endDateMultiplier($('#multiplier_end').val(), dateSelected);
  });
  if (!date) { $('#multiplier_start').val(''); }
}

/**
 * Initialize the multiplier end date picker.
 *
 * @param date
 * @param minDate
 */
function endDateMultiplier(date = false, minDate = false) {
  $('#multiplier_end').daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    startDate: date.length ? date : false,
    minDate: minDate.length ? minDate : false,
  },
  (start) => {
    const dateSelected = start.format('MM/DD/YYYY');
    $('#multiplier_end').val(dateSelected);
    startDateMultiplier($('#multiplier_start').val(), dateSelected);
  });
  if (!date) { $('#multiplier_end').val(''); }
}

export const init = () => {
  $(() => {
    /**
     *
     * INITIAL LOAD EVENTS
     *
     */

    /** INITIAL LOAD EVENTS -- END */

    /**
     *
     * EVENT TRIGGER HANDLERS
     *
     */

    $('#addnew-multiplier').on('click', (event) => {
      event.preventDefault();
      $('#multiplier-error-main-alert').hide();
      $('#multiplier-success-alert').hide();
      $('#multiplier-warning-alert').hide();
      $('#multiplier-form').trigger('reset');
      $('#multiplier_status').selectpicker('refresh');
      startDateMultiplier();
      endDateMultiplier();
      $('#multiplier-new').val('1');
      $('#multiplier_id').val('new');
      $('#multiplier-cancel').html('Cancel');
      $('#multiplier-submit').html('Add Multiplier');
      $('#multiplierModal').appendTo('body').modal('show');
    });

    $('[id^=multiplierlink]').on('click', (event) => {
      event.preventDefault();
      $('#multiplier-error-main-alert').hide();
      $('#multiplier-error-alert').hide();
      $('#multiplier-success-alert').hide();
      $('#multiplier-warning-alert').hide();
      $('#multiplier-form').trigger('reset');
      $('#multiplier_status').selectpicker('refresh');
      startDateMultiplier();
      endDateMultiplier();
      $('#multiplier-new').val('0');
      $('#multiplier-cancel').html('Cancel');
      $('#multiplier-submit').html('Update Multiplier');
      const multiplierId = $(event.currentTarget).data('multiplier');
      $('#multiplier_id').val(multiplierId);

      $.ajax({ url: `/campaign/edit/multiplier/get/process?project_id=${projectID}&campaign=${campaignID}&multiplier=${multiplierId}`, dataType: 'json' })
        .done((data) => {
          if (data.multiplier_id) {
            const {
              active, multiplier, multiplier_pending: multiplierPending, start_date: startDate, end_date: endDate,
            } = data;

            $('#multiplier_value').val(multiplier);

            if (multiplierPending) {
              $('#multiplier-warning-alert').show();

              if ($('#multiplier_approval_authority').val() === '1') {
                $('#multiplier-submit').html('Approve Multiplier');
              }

              $('#multiplier_value').val(multiplierPending);
            }

            $('#multiplier_start').val(startDate);
            $('#multiplier_end').val(endDate);
            startDateMultiplier(startDate, endDate);
            endDateMultiplier(endDate, startDate);
            $('#multiplier_status').val(active);
            $('#multiplier_status').selectpicker('refresh');
            $('#multiplierModal').appendTo('body').modal('show');
          } else {
            $('#multiplier-error-main-body').html(data.message);
            $('#multiplier-error-main-alert').slideDown(100);
          }
        })
        .fail((jqXHR, status, error) => {
          $('#multiplier-error-main-body').html(error);
          $('#multiplier-error-main-alert').slideDown(100);
        });
    });

    $('#multiplier-submit').on('click', (event) => {
      event.preventDefault();
      $('.loader, #loading-overlay').show();

      $('#multiplier-error-alert').hide();
      $('#multiplier-success-alert').hide();
      $('#multiplier-submit').addClass('btn-waiting').prop('disabled', true);
      const multiplierId = $('#multiplier_id').val();
      const multiplierValue = parseFloat($('#multiplier_value').val());

      $.ajax({
        url: `/campaign/edit/multiplier/process?project_id=${projectID}&campaign=${campaignID}&multiplier=${multiplierId}`, type: 'get', data: $('#multiplier-form').serialize(), dataType: 'json',
      })
        .done((data) => {
          $('#multiplier-submit').removeClass('btn-waiting').prop('disabled', false);
          if (data.success) {
            let icon;
            let title;
            const action = $('#multiplier-new').val() === '1' ? 'created' : 'updated';

            $('#multiplierModal').appendTo('body').modal('hide');

            icon = 'success';
            title = `Multiplier ${action} successfully.`;

            swal.fire({
              icon,
              title,
              timer: 10000,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              onClose: () => {
                window.location.reload();
              },
            });
          } else {
            $('.loader, #loading-overlay').hide();
            $('#multiplierModal').appendTo('body').modal('show');

            swal.fire({
              icon: 'error',
              title: data.message,
              timer: 10000,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            });
          }
        })
        .fail((jqXHR, status, error) => {
          $('#multiplier-submit').removeClass('btn-waiting').prop('disabled', false);
          $('.loader, #loading-overlay').hide();
          $('#multiplierModal').appendTo('body').modal('show');

          swal.fire({
            icon: 'error',
            title: error,
            timer: 10000,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          });
        });
    });

    /** EVENT TRIGGER HANDLERS -- END */
  });
};

export default { init };
