import swal from 'sweetalert2';
import JsonRpcClient from '../../JsonRpcClient';

let adsetsByID = {};
let $loader;
let client;
let onboardingClient;

/**
 *
 * FUNCTIONS
 *
 */

/**
 * Add a list of creatives to a build.
 *
 * @param buildID
 * @param creatives
 * @param ignoreWarnings
 */
function addNewCreativesToBuild(buildID, creatives, ignoreWarnings = 0) {
  client.request('post.db.add_new_creatives', { build_id: buildID, creatives, ignore_warnings: ignoreWarnings }).then((res) => {
    const { id, error_message: errorMessage, warning_message: warningMessage, validationMessages } = res;
    if (id) {
      client.request('put.db.update_build', {
        build_id: buildID, current_step: 12, status: 'new-creatives-added', can_force: '0',
      }).then((res2) => {
        if (res2.update) {
          $('#add_new_creative_to_build_modal').modal('hide');
          $loader.hide();

          swal.fire({
            icon: 'success',
            title: `Creatives added to build ${buildID} successfully`,
            timer: 3000,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(() => window.location.reload());
        }
      });
    } else {
      $loader.hide();

      if (warningMessage) {
        swal.fire({
          showConfirmButton: true,
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-secondary',
          },
          confirmButtonText: 'Yes, ignore the warnings',
          cancelButtonText: 'No',
          icon: 'question',
          title: 'Do you want to continue?',
          text: warningMessage,
        }).then((result) => {
          if (result.value) {
            $loader.show();
            addNewCreativesToBuild(buildID, creatives, 1);
          }
        });
      } else {
        let html = errorMessage ? errorMessage : 'This campaign build currently does not have the proper status to add creatives.  Please refresh the page and try again';
        html = validationMessages ? JSON.parse(validationMessages).join(' ') : html;

        swal.fire({
          icon: 'error',
          title: 'Oops...',
          html,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then();
      }
    }
  });
}

/**
 * Update build UTMs.
 *
 * @param buildID
 * @param utmData
 */
function updateUTMsForBuild(buildID, utmData) {
  client.request('post.db.update_utms_for_build', { build_id: buildID, utmData }).then((res) => {
    const { success, error_message: errorMessage } = res;
    if (success) {
      swal.fire({
        icon: 'success',
        title: `UTMs modified for build ${buildID} successfully`,
        timer: 3000,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(() => window.location.reload());
    } else {
      $loader.hide();

      swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errorMessage,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then();
    }
  });
}

class Client extends JsonRpcClient {
  constructor(endpoint = '/perfectbuild/rpc', defaultHeaders = { Accept: 'application/json', 'Content-Type': 'application/json' }, headers = {}) {
    super(endpoint, defaultHeaders, headers);
  }
}

class OnboardingClient extends JsonRpcClient {
  constructor(endpoint = '/project/onboarding/rpc', defaultHeaders = { Accept: 'application/json', 'Content-Type': 'application/json' }, headers = {}) {
    super(endpoint, defaultHeaders, headers);
  }

  /**
   *
   * @param userID
   * @param token1
   *
   * @returns {Promise}
   */
  updateMyLogin(userID, token) {
    const params = {
      user_id: userID,
      token,
    };

    return this.request('post.dj.update_my_login', params);
  }
}

/**
 * Handle FB login response.
 *
 * @param response
 */
function statusChangeCallback(response) {
  if (response.status === 'connected') {
    $loader.show();
    $('#loading-overlay').show();

    // Save our token
    onboardingClient.updateMyLogin(response.authResponse.userID, response.authResponse.accessToken).then((result) => {
      if (result.error != null) {
        // We ran into an error so log them out of FB
        swal.fire({
          icon: 'error',
          title: 'Error!',
          text: result.error_message,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(() => window.location.reload());

        // Log us out so we can log in as someone else
        window.FB.logout();

        // Remove our facebook login cookie
        document.cookie = `fblo_${window.FB_APP_ID}=n; Path=/; domain=${window.location.hostname}`;
      } else {
        window.location.reload();
      }
    });
  } else if (response.status === 'unknown') {
    // Something is wrong with our cookie so lets try and wipe it out
    document.cookie = `fblo_${window.FB_APP_ID}=n; Path=/; domain=${window.location.hostname}`;
  } else {
    // The person is not logged into your app or we are unable to tell.
    console.log('NOT CONNECTED');
  }
}

/**
 * Handle a FB login form if it is present.
 */
function handleFBLogin() {
  const $FBLoginButton = $('.fbLogin');

  if ($FBLoginButton.length > 0) {
    // Handle cases where the user is not signed into the Jungle with FB
    // Process the results of the FB Login button within fb-login.php
    $(document).on('FBLoginStatusChangeEvent', () => {
      window.FB.getLoginStatus((response) => {
        statusChangeCallback(response);
      });
    });

    // Watch for SDK failures
    setTimeout(() => {
      if (window.fbAccountLoaded === false) {
        $('.fbLogin').hide();
        $('.script-blocked').show();
      }
    }, 5000);

    window.FB.init({
      appId: window.FB_APP_ID,
      cookie: false, // enable cookies to allow the server to access the session
      xfbml: true, // parse social plugins on this page
      version: window.FB_GRAPH_VERSION, // current graph api version
    });

    window.FB.Event.subscribe('xfbml.render', () => {
      window.fbAccountLoaded = true;

      $('.fbLogin #loading').remove();
      $('.fbLogin').attr('style', '');
      $('.fb_iframe_widget').css('display', 'block');
    });
  }
}

export const init = () => {
  $(() => {
    /**
     *
     * INITIAL LOAD EVENTS
     *
     */
    $loader = $('.loader, #loading-overlay');
    client = new Client();
    onboardingClient = new OnboardingClient();

    // Look for rows that have a data-href that has a value double data-href is intentional
    $('#buildsTable tbody tr[data-href!=""][data-href]').on('click', (e) => {
      e.preventDefault();

      const link = $(e.currentTarget).data('href');

      if (link.length > 0) {
        window.location.href = link;
      }
    });

    $('.duplicate_build').on('click', (event) => {
      swal.fire({
        icon: 'warning',
        title: 'Are you sure?!',
        text: 'Are you sure you would like to duplicate this build?!',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary',
        },
      }).then((ans) => {
        if (ans.value) {
          $('.loader, #loading-overlay').show();
          client.request('post.db.duplicate_build', { build_id: $(event.currentTarget).data('buildid') }).then((res) => {
            $('.loader, #loading-overlay').hide();
            // console.log(res);
            if (res.error) {
              swal.fire({
                icon: 'error',
                title: 'Error!',
                text: res.error_message,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then();
            } else {
              window.location.assign(`/perfectbuild?build_id=${res.build_id}&step=1`);
            }
          });
        }
      });
    });

    $('#add_new_creative_to_build').on('click', (e) => {
      e.preventDefault();
      $('#add_new_creative_to_build_modal').appendTo('body');
    });

    $('#add_new_creative_to_build_modal').on('shown.bs.modal', () => {
      $('#add_new_creative_to_build_modal').trigger('focus');
    });

    $('#add_new_creative_to_build_modal').on('show.bs.modal', (event) => {
      const modal = $(event.currentTarget);
      const button = $(event.relatedTarget);
      const buildID = button.data('buildid');
      const buildName = button.data('buildname');

      modal.find('.modal-title').text(`Add New Creative to build #${buildID} - ${buildName}.`);
      modal.find('#add_new_creative_to_build_modal_hidden_build_id').val(buildID);

      // Get a list of adsets already in the build
      client.request('get.db.new_creatives_for_build', { build_id: buildID }).then((res) => {
        adsetsByID = {};

        if (!Array.isArray(res) && Object.keys(res).length > 0) {
          Object.values(res).forEach((adSets) => {
            adsetsByID[adSets.adset_id] = { active_in_build: adSets.activeInBuild };
          });

          $('#add_new_creative_to_build_add_button').removeAttr('disabled');
        }
      });

      // Get our table and JS for the creative table
      $.ajax({ url: `/perfectbuild/new-creatives-for-build/get?build_id=${buildID}`, type: 'get' })
        .done((data) => {
          const html = $.parseHTML(data, document, true);
          modal.find('#newCreativesForBuild').html('').append(html);
        });
    });

    $('#add_new_creative_to_build_add_button').on('click', () => {
      $loader.show();
      const buildID = $('#add_new_creative_to_build_modal_hidden_build_id').val();

      const creatives = [];
      const creativesWithoutPlacements = [];

      $('#newCreativesForBuild input[name^="creative"]:checked').each(function processCheckedCreatives() {
        const creativeID = $(this).attr('name').replace(/[^\d]/g, '');
        const creative = {
          id: creativeID,
          adset_id: $(this).closest('tr').data('adset-id'),
          name: $(this).closest('div').text(),
          blended: $(`#newCreativesForBuild input[name="blended[${creativeID}]"]`).is(':checked'),
          blended_stories: $(`#newCreativesForBuild input[name="blended_stories[${creativeID}]"]`).is(':checked'),
          blended_reels: $(`#newCreativesForBuild input[name="blended_reels[${creativeID}]"]`).is(':checked'),
          place_on_fb_feed: $(`#newCreativesForBuild input[name="fb_feed[${creativeID}]"]`).is(':checked'),
          place_on_fb_stories: $(`#newCreativesForBuild input[name="fb_stories[${creativeID}]"]`).is(':checked'),
          place_on_fb_reels: $(`#newCreativesForBuild input[name="fb_reels[${creativeID}]"]`).is(':checked'),
          place_on_ig_feed: $(`#newCreativesForBuild input[name="ig_feed[${creativeID}]"]`).is(':checked'),
          place_on_ig_stories: $(`#newCreativesForBuild input[name="ig_stories[${creativeID}]"]`).is(':checked'),
          place_on_ig_reels: $(`#newCreativesForBuild input[name="ig_reels[${creativeID}]"]`).is(':checked'),
          auto: $(`#newCreativesForBuild input[name="auto[${creativeID}]"]`).is(':checked'),
        };

        if (!creative.blended && !creative.place_on_fb_feed && !creative.place_on_ig_feed && !creative.blended_stories && !creative.blended_reels && !creative.place_on_fb_stories && !creative.place_on_fb_reels && !creative.place_on_ig_stories && !creative.place_on_ig_reels && !creative.auto) {
          creativesWithoutPlacements.push($(this).closest('div').text());
        }

        creatives.push(creative);
      });

      if (creativesWithoutPlacements.length > 0) {
        swal.fire({
          icon: 'error',
          title: 'Error!',
          text: `You must select a placement for ${creativesWithoutPlacements.join(', ')}`,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then();
        $loader.hide();
        return false;
      }

      if (!creatives || creatives.length === 0) {
        swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'You must select at least one creative!',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then();
        $loader.hide();
        return false;
      }

      let creativeAdSetsActiveInBuild = '';

      creatives.forEach((creative) => {
        if (adsetsByID[creative.adset_id] != null && adsetsByID[creative.adset_id].active_in_build) {
          creativeAdSetsActiveInBuild += `${creative.name}<br />`;
        }
      });

      if (creativeAdSetsActiveInBuild.length > 0) {
        swal.fire({
          title: 'Are you sure?',
          html: `<p>You have chosen to add the following creatives to existing ad sets in Facebook. Doing this may not allow your ads to compete with currently running ads. Are you sure you want to continue?</p>
            ${creativeAdSetsActiveInBuild}`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-secondary',
          },
          confirmButtonText: 'Yes, add them!',
        }).then((result) => {
          if (result.value) {
            addNewCreativesToBuild(buildID, creatives);
          } else {
            $loader.hide();
          }
        });
      } else {
        addNewCreativesToBuild(buildID, creatives);
      }

      return true;
    });

    $('#update_utm_modal #update_utm').on('click', (e) => {
      e.preventDefault();
      $('#update_utm_modal #update_utm_modal').appendTo('body');
    });

    $('#update_utm_modal #update_utm_modal').on('shown.bs.modal', () => {
      $('#update_utm_modal #update_utm_modal').trigger('focus');
    });

    $('#update_utm_modal #use-utm').on('change', () => {
      $('#update_utm_modal #utm_block').css('display', $('#update_utm_modal #use-utm').prop('checked') ? 'block' : 'none');

      if ($('#update_utm_modal #custom_toggle').prop('checked')) {
        $('#update_utm_modal #utm-inputs').css('display', 'none');
        $('#update_utm_modal #custom_block').css('display', 'block');
      } else {
        $('#update_utm_modal #utm-inputs').css('display', 'block');
        $('#update_utm_modal #custom_block').css('display', 'none');
      }
    });

    $('#update_utm_modal #custom_toggle').on('change', () => {
      if ($('#update_utm_modal #custom_toggle').prop('checked')) {
        $('#update_utm_modal #utm-inputs').css('display', 'none');
        $('#update_utm_modal #custom_block').css('display', 'block');
      } else {
        $('#update_utm_modal #utm-inputs').css('display', 'block');
        $('#update_utm_modal #custom_block').css('display', 'none');
      }
    });

    $('#update_utm_modal').on('show.bs.modal', (event) => {
      const modal = $(event.currentTarget);
      const button = $(event.relatedTarget);
      const buildID = button.data('buildid');
      const buildName = button.data('buildname');

      $('.loader, #loading-overlay').show();

      modal.find('.modal-title').text(`Modify UTMs for build #${buildID} - ${buildName}.`);
      modal.find('#update_utm_modal_hidden_build_id').val(buildID);

      // Pull UTM data
      client.request('get.db.utms_for_build', { build_id: buildID }).then((res) => {
        const {
          use_utm: useUtm,
          utm_campaign: utmCampaign,
          utm_content: utmContent,
          utm_custom: utmCustom,
          utm_medium: utmMedium,
          utm_source: utmSource,
          utm_term: utmTerm,
        } = res;

        $('#update_utm_modal #custom_input').val(utmCustom ?? '');
        $('#update_utm_modal #utm_source').val(utmSource ?? '{{site_source_name}}');
        $('#update_utm_modal #utm_medium').val(utmMedium ?? '{{placement}}');
        $('#update_utm_modal #utm_campaign').val(utmCampaign ?? '{{campaign.id}}');
        $('#update_utm_modal #utm_term').val(utmTerm ?? 'mnk');
        $('#update_utm_modal #utm_content').val(utmContent ?? '{{ad.id}}');

        $('#update_utm_modal #use-utm').prop('checked', useUtm === '1').trigger('change');
        $('#update_utm_modal #custom_toggle').prop('checked', utmCustom).trigger('change');

        $('.loader, #loading-overlay').hide();
      });
    });

    $('#update_utm_modal #update_utm_button').on('click', () => {
      $loader.show();
      const buildID = $('#update_utm_modal #update_utm_modal_hidden_build_id').val();
      const useUtm = $('#update_utm_modal #use-utm').prop('checked') ? 1 : 0;
      const custom = $('#update_utm_modal #custom_toggle').prop('checked');
      let utmSource = $('#update_utm_modal #utm_source').val();
      let utmMedium = $('#update_utm_modal #utm_medium').val();
      let utmCampaign = $('#update_utm_modal #utm_campaign').val();
      let utmTerm = $('#update_utm_modal #utm_term').val();
      let utmContent = $('#update_utm_modal #utm_content').val();
      let customInput = $('#update_utm_modal #custom_input').val();

      if (useUtm) {
        if (custom && customInput.length === 0) {
          swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'You must enter a custom URL tag!',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(() =>{
            $('.loader, #loading-overlay').hide();
          });

          return false;
        } else if (!custom && (utmSource.length === 0 && utmMedium.length === 0 && utmCampaign.length === 0 && utmTerm.length === 0 && utmContent.length === 0)) {
          swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'You must enter at least one UTM information!',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(() =>{
            $('.loader, #loading-overlay').hide();
          });

          return false;
        }
      }

      swal.fire({
        title: 'Are you sure?',
        html: '<p>You have chosen to modify the UTMs for this build. Are you sure you want to continue?</p>',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary',
        },
        confirmButtonText: 'Yes, update them!',
      }).then((result) => {
        if (result.value) {
          // Determine what our final utms will look like
          if (useUtm) {
            if (custom) {
              // We are using a custom string so clear all of the standard items
              utmSource = null;
              utmMedium = null;
              utmCampaign = null;
              utmTerm = null;
              utmContent = null;
            } else {
              // We are using standard items so clear the custom utm string
              customInput = null;
            }
          } else {
            // We have turned off UTMs so lets clear all of the utm items
            utmSource = null;
            utmMedium = null;
            utmCampaign = null;
            utmTerm = null;
            utmContent = null;
            customInput = null;
          }

          const utmData = {
            useUtm,
            custom,
            utmSource,
            utmMedium,
            utmCampaign,
            utmTerm,
            utmContent,
            customInput,
          };

          updateUTMsForBuild(buildID, utmData);
        } else {
          $loader.hide();
        }
      });

      return true;
    });

    $('#tos_agree_button').click(() => {
      const fbAdAccountId = $('#FB_ACCOUNT_ID').data('value');
      const tosIDs = $('#tos_ids').data('value');

      swal.fire({
        showConfirmButton: true,
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary',
        },
        confirmButtonText: 'Yes, I\'ve read and agree',
        cancelButtonText: 'Cancel',
        icon: 'question',
        title: 'Facebook Terms Agreement',
        text: 'Are you sure you\'ve read and agree to all of Facebook\'s Terms?',
      }).then((result) => {
        if (result.value) {
          $('.loader, #loading-overlay').show();

          client.request('post.fb.accept_facebook_tos', { facebook_account_id: fbAdAccountId, tos_ids: tosIDs }).then((res) => {
            if (res.error) {
              swal.fire({
                icon: 'error',
                title: 'Error!',
                text: res.error_message,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => {
                $('.loader, #loading-overlay').hide();
              });
            } else {
              swal.fire({
                icon: 'success',
                title: 'Terms Accepted!',
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => {
                window.location.reload();
              });
            }
          }).catch(() => {
            swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Unexpected Error!',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            }).then(() => {
              $('.loader, #loading-overlay').hide();
            });
          });
        }
      });
    });

    handleFBLogin();

    /** INITIAL LOAD EVENTS -- END */

    /**
     *
     * EVENT TRIGGER HANDLERS
     *
     */

    /** EVENT TRIGGER HANDLERS -- END */
  });
};

export default { init };
