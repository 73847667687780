/**
 *
 * @param selector
 */
export const handleSelectpickerWithAll = (selector) => {
  const $dropDown = $(selector);
  let isAllSelected = $dropDown.find('option[value="All"]').prop('selected');
  const lastIsAllSelected = $dropDown.data('all');
  const numOfOtherOptions = $dropDown.find('option[value!="All"]').length;
  const selectedOptions = ($dropDown.val()) ? $dropDown.val() : [];
  const numOfOptionsSelected = selectedOptions.length;

  if (isAllSelected === lastIsAllSelected) {
    const numOfOtherOptionsSelected = ($.inArray('All', selectedOptions) >= 0) ? numOfOptionsSelected - 1 : numOfOptionsSelected;

    // Have we selected every option?
    isAllSelected = (numOfOtherOptionsSelected >= numOfOtherOptions);

    $dropDown.find('option[value="All"]').prop('selected', isAllSelected).parent().selectpicker('refresh');
  } else {
    // Select/Deselect all options in the dropdown
    $dropDown.find('option').prop('selected', isAllSelected).parent().selectpicker('refresh');
  }

  $dropDown.data('all', isAllSelected);
};
