import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { init as initEdit } from './edit/index';

$(() => {
  $.validator.setDefaults({
    ignore: ':not(:visible)',
    highlight(element) {
      $(element).closest('.form-group').addClass('has-danger');
    },
    unhighlight(element) {
      $(element).closest('.form-group').removeClass('has-danger');
    },
    errorElement: 'small',
    errorClass: 'form-control-feedback d-block',
    errorPlacement(error, element) {
      if (element.parent('.input-group').length) {
        error.insertAfter(element.parent());
      } else if (element.parent('.bootstrap-select').length) {
        error.insertAfter(element.parent());
      } else if (element.prop('type') === 'checkbox' || element.prop('type') === 'radio') {
        error.appendTo(element.parent());
      } else {
        error.insertAfter(element);
      }
    },
  });

  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  // if ($('body').hasClass('campaign_add')) {
  //   initAdd();
  // }
  if ($('body').hasClass('campaign_edit')) {
    initEdit();
  }
  // if ($('body').hasClass('campaign_list')) {
  //   initList();
  // }
  /** INITIAL LOAD EVENTS -- END */
});
